import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

const theme = 'dark'

export default function NotFound() {
    return (
        <Layout theme={ theme } currentPath = 'not-found'>

            <Helmet>
                <title>{'VOLNA / Not found'}</title>
                <meta name="description" content="Nothing here, get back to works" />
                <link rel="canonical" href="https://volna-media.com/404" />
                <meta property="og:url" content="https://volna-media.com/404" />
                <meta property="og:title" content='VOLNA / Not found' />
                <meta property="og:description" content="Nothing here, get back to works" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:creator" content="VOLNA" />
                <meta name="twitter:title" content='VOLNA / Not found' />
                <meta name="twitter:description" content="Nothing here, get back to works" />
            </Helmet>

                <div className='not-found'>
                    <div className="xl-heading">Nothing here, get back to <Link to="/works">works</Link></div>
                </div>
        </Layout>
    )
}
